
import React from "react";

export const RoadMap = () => {
  return (
    <div id="portfolio" className="text-center">
      <div className="container">
      <h1 className="setion_title">ROADMAP</h1>
          <section className="timeline">
            <ol>
              <li>
                <div>
                  <time className="text-white">Phase 1: The Big Bang</time> DogeEverse starts on a level playing field with a fair presale. The team will allocate a portion of funds raised towards a professional marketing strategy.
                </div>
              </li>
              <li>
                <div>
                  <time className="text-white">Phase 2: Development</time> Presale buyers of DogeEverse will be able to stake their tokens into the smart contract before listing day to benefit from the high early rewards opportunity.
                </div>
              </li>
              <li>
                <div>
                  <time className="text-white">Phase 3: Awareness</time> The DogeEverse staking smart contract is programmed to distribute tokens to all stakers in the pool over a period of two years, providing sustained passive rewards.
                </div>
              </li>
              <li>
                <div>
                  <time className="text-white">Phase 4: Expansion</time> DogeEverse will launch on Uniswap, the largest decentralized exchange. This will provide the best trading environment and early liquidity.
                </div>
              </li>
              <li>
                <div>
                  <time className="text-white">Phase 5: Cosmic Travel</time> The DogeEverse team will add a substantial liquidity pool to Uniswap. For this purpose, 10% of the total token supply has been set aside.
                </div>
              </li>
              <li>
                <div>
                  <time className="text-white">Phase 6: Go Live Binance, OKX, CoinBase</time> The DogeEverse becomes a token that can be bought and sold on centralized exchanges.
                </div>
              </li>
              <li></li>
            </ol>
          </section>
      </div>
    </div>
  );
};
