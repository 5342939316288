import React from "react";

export const Featured = () => {
  return (
    <div className="container text-center">
      <h1 className="setion_title text-center">Featured In</h1>
      <div className="row justify-content-center d-none d-lg-flex d-flex block-top">
          <img src="img/cointelegraph.svg" alt="Logo 1" className="img-fluid" />
          <img src="img/techopedia.svg" alt="Logo 2" className="img-fluid" />
          <img src="img/coindesk.svg" alt="Logo 3" className="img-fluid" />
          <img src="img/bitcoinst.svg" alt="Logo 4" className="img-fluid" />
          <img src="img/cryptonews.svg" alt="Logo 5" className="img-fluid" />
      </div>
    </div>
  );
};
