import React, { useState, useEffect,useMemo } from "react";
import { Navigation } from "../components/Navigator/navigation";
import { OutMore } from "../components/ourmore/outmore";
import { Featured } from "../components/Featured/featured";
import { RoadMap } from "../components/Roadmap/roadmap";
import { Rewards } from "../components/Rewards/rewards";
import { TokenNomics } from "../components/Tokenmics/tokenomics";
import JsonData from "../data/data.json";
import SmoothScroll from "smooth-scroll";
import { Footer } from "../components/Footer/footer";
import {
  ConnectionProvider,
  WalletProvider,
} from "@solana/wallet-adapter-react";
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import {
  WalletModalProvider,
  WalletMultiButton,
} from "@solana/wallet-adapter-react-ui";
import { clusterApiUrl } from "@solana/web3.js";
import "./claim.css";
import configs from '../config.main.json'
import { ClaimForm } from "../components/claimForm";
export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const ClaimPage = () => {
  const [landingPageData, setLandingPageData] = useState({});

  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);


  return (
    // <ConnectionProvider endpoint={configs.SolRpcApi}>
    //   <WalletProvider wallets={wallets} autoConnect>
    //     <WalletModalProvider>
          <div id="container">
            <Navigation />
            {/* <Header /> */}
            <ClaimForm />
            <OutMore data={landingPageData.Features} />
            <Featured data={landingPageData.About} />
            <RoadMap data={landingPageData.Gallery} />
            <TokenNomics data={landingPageData.Testimonials} />
            <Rewards data={landingPageData.Contact} />
            <Footer/>
          </div>
    //     </WalletModalProvider>
    //   </WalletProvider>
    // </ConnectionProvider>

   
  );
};

export default ClaimPage;
