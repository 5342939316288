import { Routes, Route, BrowserRouter } from 'react-router-dom';
import App from './App'; // Replace with your component paths
import ClaimPage from './pages/claim';

function AppRoutes(){
  return (<Routes>
    <Route path="/" element={<App />} />
    <Route path="/*" element={<App />} />
      {/* Catch-all route for any other path */}
  </Routes>
);
}

export default AppRoutes;