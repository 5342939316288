import React from "react";

export const TokenNomics = () => {
  return (
    <div id="testimonials">
      <div className="container">
        <h1 className="setion_title text-center padding-bottom">TOKENOMICS</h1>
        <div className="row padding-bottom" >
          <div className="col-12 col-md-6">
            <div className="tokenomics-box">
              <img src="/img/token.svg" className="img-fluid tokenomics-image" />
              <h1 className="font-32 fw-bold text-white text-align-left ">DOGEVERSE HAS A TOTAL SUPPLY OF 200,000,000,000 TOKENS</h1>
              <p className="font-18-real text-secondary px-3 mb-3 text-align-left">The <span className="text-gradient mx-1">$DOGEVERSE</span> token is minted on Ethereum and is unique in that it can also be claimed, stored and traded on Solana, BNB Chain, Base, Polygon, or Avalanche using Wormhole and Portal Bridge tech.</p>
              <div className="w-100 card-row card-title fw-bold">
                <div className="card-item">Fund</div>
                <div className="card-item">% Allocation</div>
                <div className="card-item">Token</div>
              </div>
              <div className="w-100 card-row fw-bold">
                <div className="card-item wd-35 wd-sm-30">
                <span className="bgcolor-1 dot"></span>
                 Presale Allocation
                </div>
                <div className="card-item"> 15% </div>
                <div className="card-item"> 30,000,000,000  </div>
              </div>
              <div className="w-100 card-row fw-bold">
                <div className="card-item wd-35 wd-sm-30">
                <span className="bgcolor-2 dot"></span>
                Staking
                </div>
                <div className="card-item"> 10% </div>
                <div className="card-item"> 20,000,000,000  </div>
              </div>
              <div className="w-100 card-row fw-bold">
                <div className="card-item wd-35 wd-sm-30">
                <span className="bgcolor-3 dot"></span>
                Project Funds
                </div>
                <div className="card-item"> 25% </div>
                <div className="card-item"> 50,000,000,000  </div>
              </div>
              <div className="w-100 card-row fw-bold">
                <div className="card-item wd-35 wd-sm-30">
                <span className="bgcolor-4 dot"></span>
                Liquidity
                </div>
                <div className="card-item"> 10% </div>
                <div className="card-item"> 20,000,000,000  </div>
              </div>
              <div className="w-100 card-row fw-bold">
                <div className="card-item wd-35 wd-sm-30">
                <span className="bgcolor-5 dot"></span>
                Marketing
                </div>
                <div className="card-item"> 25% </div>
                <div className="card-item"> 50,000,000,000  </div>
              </div>
              <div className="w-100 card-row fw-bold">
                <div className="card-item wd-35 wd-sm-30">
                <span className="bgcolor-6 dot"></span>
                Ecosystem Funds
                </div>
                <div className="card-item"> 10% </div>
                <div className="card-item"> 20,000,000,000  </div>
              </div>
              <div className="w-100 card-row fw-bold">
                <div className="card-item wd-35 wd-sm-30">
                <span className="bgcolor-7 dot"></span>
                Exchanges
                </div>
                <div className="card-item"> 5% </div>
                <div className="card-item"> 10,000,000,000  </div>
              </div>
            </div>
          </div>
          <div className=" col-12 col-md-5 d-flex justify-content-center">
            <img src="/img/tokenomics-img.svg" className="postion-absolute" />
          </div>
        </div>
      </div>
    </div>
  );
};
