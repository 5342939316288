import React, { useState,useEffect } from "react";

export const Navigation = () => {
  const [currentLanguage, setCurrentLanguage] = useState("en"); // Default language is English
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1024); // Adjust the breakpoint as needed
    };

    handleResize(); // Check initial viewport width
    window.addEventListener('resize', handleResize); // Add event listener for window resize

    return () => window.removeEventListener('resize', handleResize); // Cleanup on unmount
  }, []);

  const languageOptions = {
    en: {
      flag: "fi fi-gb width-size",
      name: "English",
      selectLanguage: "EN",
    },
    cn: {
      flag: "fi fi-cn",
      name: "中文",
      selectLanguage: "选择语言",
    },
    es: {
      flag: "fi fi-es",
      name: "Español",
      selectLanguage: "Seleccionar Idioma",
    },
    jp: {
      flag: "fi fi-jp",
      name: "日本語",
      selectLanguage: "言語を選択",
    },
    vn: {
      flag: "fi fi-vn",
      name: "Tiếng Việt",
      selectLanguage: "Chọn Ngôn Ngữ",
    },
    de: {
      flag: "fi fi-de",
      name: "Deutsch",
      selectLanguage: "Sprache auswählen",
    },
  };

  const handleChangeLanguage = (language) => {
    setCurrentLanguage(language);
  };

  const scrollToBuySection = () => {
    // Find the target section to scroll to
    // let section = null;
    // // if(isMobile){
    // //   section = document.getElementById('buyForm');
    // // }else{
    //   section = document.getElementById('intro');
    // // }
    // if (!section) {
      window.location = "/"
      // return
    // }
    // // Scroll to the section
    // section.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <nav id="menu" className="navbar navbar-default navbar-fixed-top">
      <div >
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
            style={{float:'none', color:""}}
          >
            {" "}
            <span className="sr-only">Toggle navigation</span>{" "}
            <span className="icon-bar" style={{ backgroundColor: 'rgb(144, 34, 255)' }}></span>{" "}
            <span className="icon-bar" style={{ backgroundColor: 'rgb(144, 34, 255)' }}></span>{" "}
            <span className="icon-bar" style={{ backgroundColor: 'rgb(144, 34, 255)' }}></span>{" "}
          </button>
          <a className="navbar-brand page-scroll" href="/" style={{float:'none'}}>
            <img src="/img/token.svg" width={"40"} height={"40"} className="padding-top-10" style={{ "display": "inline" }} />
            <img src="/img/logo.svg" className="padding-top-10" style={{ "display": "inline" }} />
          </a>{" "}
          <button className="navbar-toggle collapsed btn-buynow" style={{
            cursor: 'pointer',
            borderRadius: '8px',
            border: '1px solid #171717',
            textTransform: 'capitalize',
            color: "#2a2a2a",
            }}
            onClick={scrollToBuySection}>
              BUY NOW
            </button>

        </div>
        <div className="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
          <ul className="nav navbar-nav navbar-right">
            <li>
              <a href="/" className="page-scroll">
                  STAKING
              </a>
            </li>
            <li>
              <a href="/" className="page-scroll">
                ABOUT
              </a>
            </li>
            <li>
              <a href="/" className="page-scroll">
                ROADMAP
              </a>
            </li>
            <li>
              <a href="#" className="page-scroll">
                HOW TO BUY
              </a>
            </li>
            <li>
              <a href="#" className="page-scroll">
                FAQ
              </a>
            </li>
          </ul>
          {!isMobile&&
          <div className="buy-and-contact">
            <button className="noboder-nobackground">
              <a href="https://twitter.com/TheDogVerse" className="page-scroll">
                <img src="img/twitter.svg" />
              </a>
            </button>
            <button className="noboder-nobackground">
              <a href="https://t.me/The_DogeVerse" className="page-scroll">
              <img src="img/telegram.svg" />
              </a>
            </button>
            <button className="btn-buynow" onClick={scrollToBuySection}>
                BUY NOW
            </button>
            <button
              className="dropdown-toggle btn btn-default btn-english"
              type="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <span className={languageOptions[currentLanguage].flag}></span>
              {languageOptions[currentLanguage].selectLanguage}{" "}
              <span className="caret"></span>
            </button>
            <ul className="dropdown-menu">
              {Object.keys(languageOptions).map((language) => (
                <li key={language}>
                  <a
                    href="#"
                    onClick={() => handleChangeLanguage(language)}
                  >
                    <span className={languageOptions[language].flag}></span>
                    {languageOptions[language].name}
                  </a>
                </li>
              ))}
            </ul>
          </div>
          }
        </div>
      </div>
    </nav>
  );
};
