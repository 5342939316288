import React, { useState } from 'react';
import Decimal from 'decimal.js';


export const Rewards = () => {
  const [progress, setProgress] = useState(40);
  const [inputValue, setInputValue] = useState(1000000);
  const [iBuy, setIBuy] = useState(1000000 * 0.00022);

  const scrollToBuySection = () => {
    // Find the target section to scroll to
    const section = document.getElementById('intro');

    // Scroll to the section
    section.scrollIntoView({ behavior: 'smooth' });
  };


  const handleChange = (event) => {
    setInputValue(event.target.value);
    const inputNumber = new Decimal(event.target.value);
    const result = inputNumber.times(0.00022);
    setIBuy(result.toString());
  };

  const handleKeyPress = (event) => {
    setInputValue(event.target.value);
    const inputNumber = new Decimal(event.target.value);
    const result = inputNumber.times(0.00022);
    setIBuy(result.toString());
  };

  return (
    <div>
      <div id="contact">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-4">
              <h1   className="font-32 font-sm-48 text-white text-uppercase fw-bold pt-sm-5">How To Buy?</h1>
            </div>
            <div className="col-12 col-lg-4">
              <div className="box">
                <span   className="font-18 fw-semibold text-align-left">Step 1</span>
                <h5   className="font-26 fw-semibold pt-2 text-align-left">Connect Wallet</h5>
                <p   className="font-17 fw-medium p-0 m-0 text-align-left">Find the presale widget at the top of this web page and connect your preferred wallet. There are various options to choose from.</p>
              </div>
            </div>
            <div className="col-12 col-lg-4">
              <div className="box">
                <span   className="font-18 fw-semibold text-align-left">Step 2</span>
                <h5   className="font-26 fw-semibold pt-2 text-align-left">Select Network</h5>
                <p   className="font-17 fw-medium p-0 m-0 text-align-left">Once connected, you can choose to participate in the Dogeverse presale on Ethereum, BNB Chain, Polygon, Avalanche or Base. Solana is also coming soon!</p>
              </div>
            </div>
            <div className="col-12 col-lg-4">
              <div className="box">
                <span   className="font-18 fw-semibold text-align-left">Step 3</span>
                <h5   className="font-26 fw-semibold pt-2 text-align-left">Buy Tokens</h5>
                <p   className="font-17 fw-medium p-0 m-0 text-align-left">Enter the amount of ETH, BNB, MATIC, AVAX, USDT or USDC you wish to swap for $DOGEVERSE and confirm the transaction in your wallet.</p>
              </div>
            </div>
            <div className="col-12 col-lg-4">
              <div className="box">
                <span   className="font-18 fw-semibold text-align-left">Step 4</span>
                <h5   className="font-26 fw-semibold pt-2 text-align-left">Claim on Chain</h5>
                <p   className="font-17 fw-medium p-0 m-0 text-align-left">Once the presale concludes, you will be able to claim your $DOGEVERSE tokens using the same wallet and chain you used to buy.</p>
              </div>
            </div>
            <div className="col-12 col-lg-4">
              <div className="box">
                <span   className="font-18 fw-semibold text-align-left">Step 5</span>
                <h5   className="font-26 fw-semibold pt-2 text-align-left">Bridge or Trade</h5>
                <p   className="font-17 fw-medium p-0 m-0 text-align-left">$DOGEVERSE will be tradable on all major chains and DEXs. Simply use the Dogeverse Bridge to easily switch tokens between chains.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
