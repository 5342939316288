import React from "react";
import { Navbar, Nav, Container, Row, Col, Card } from 'react-bootstrap';

export const OutMore = () => {
  const scrollToBuySection = () => {
    // Find the target section to scroll to
    const section = document.getElementById('intro');

    // Scroll to the section
    section.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div id="features" className="text-center" style={{paddingTop:100}}>
      <div>
        <div className="whitepaper">
          <h1>DOGE GOES MULTICHAIN</h1>
          <h1>Buy and claim <span className="text-gradient mx-1">$DOGEVERSE</span> on any of these chains</h1>
            <div className="d-flex align-items-center justify-content-center">
              <div className="d-flex align-items-center justify-content-center mt-3 mx">
                <div className="position-relative mx-2 d-flex flex-column align-items-center">
                  <img src="img/ETH.svg" height={'98px'} />
                  <div className="status d-flex align-items-center justify-content-center">
                    <img src="img/green.svg" />
                    <span className="font-9 text-dark text-uppercase ps-1 fw-semibold">live</span>
                  </div>
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-center mt-3 mx">
                <div className="position-relative mx-2 d-flex flex-column align-items-center">
                  <img src="img/bnb.svg" height={'98px'} />
                  <div className="status d-flex align-items-center justify-content-center">
                    <img src="img/green.svg" />
                    <span className="font-9 text-dark text-uppercase ps-1 fw-semibold">live</span>
                  </div>
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-center mt-3 mx">
                <div className="position-relative mx-2 d-flex flex-column align-items-center">
                  <img src="img/polygon.svg" height={'98px'} />
                  <div className="status d-flex align-items-center justify-content-center">
                    <img src="img/inprogress.svg" />
                    <span className="font-9 text-dark text-uppercase ps-1 fw-semibold">Coming Soon</span>
                  </div>
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-center mt-3 mx">
                <div className="position-relative mx-2 d-flex flex-column align-items-center">
                  <img src="img/AVAX.svg" height={'98px'} />
                  <div className="status d-flex align-items-center justify-content-center">
                    <img src="img/inprogress.svg" />
                    <span className="font-9 text-dark text-uppercase ps-1 fw-semibold">Coming Soon</span>
                  </div>
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-center mt-3 mx">
                <div className="position-relative mx-2 d-flex flex-column align-items-center">
                  <img src="img/BASE.svg" height={'98px'} />
                  <div className="status d-flex align-items-center justify-content-center">
                    <img src="img/inprogress.svg" />
                    <span className="font-9 text-dark text-uppercase ps-1 fw-semibold">Coming Soon</span>
                  </div>
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-center mt-3 mx">
                <div className="position-relative mx-2 d-flex flex-column align-items-center">
                  <img src="img/sol-about.svg" height={'98px'} />
                  <div className="status d-flex align-items-center justify-content-center">
                    <img src="img/green.svg" />
                    <span className="font-9 text-dark text-uppercase ps-1 fw-semibold">live</span>
                  </div>
                </div>
              </div>
            </div>
        </div>
        <div className="row" style={{margin:0}}>
          <div className="col-lg-4 position-relative">
            <div className="box">
              <h2 className="fw-bold text-white font-35 text-uppercase text-align-left">
              Cosmo the Chain Hopping Doge
              </h2>
              <p className="text-white font-18 pt-2 fw-medium text-align-left">
              Spawned by a collapsing supernova, Cosmo the Doge was born with the unique ability to hyperjump between the stars of the crypto universe. His time-tested voyages across Ethereum and Solana transformed the Doge Meme into a symbol of unity and creativity, giving rise to the Dogeverse. Now, Cosmo has discovered a way to share his chain-traveling ability so that $DOGEVERSE holders can freely traverse the best crypto chains. What a good boy.
              </p>
              <img src="img/palm.svg" />
            </div>
          </div>
          <div className="col-lg-4 position-relative">
            <div className="box">
              <h2 className="fw-bold text-white font-35 text-uppercase text-align-left">
              THE DOGEVERSE IS MULTICHAIN
              </h2>
              <p className="text-white font-18 pt-2 fw-medium text-align-left">
              Dogeverse is the first Doge Meme token built on a multichain network spanning Ethereum, BNB Chain, Polygon, Solana, Avalanche and Base. Welcome to an interconnected universe of doge lovers where community, rewards, and creative fun converge. Stake your $DOGEVERSE tokens on Ethereum to earn passive rewards and increase your bags throughout meme season.
              </p>
              <img src="img/palm.svg" />
            </div>
          </div>
          <div className="col-lg-4 position-relative">
            <div className="box">
              <h2 className="fw-bold text-white font-35 text-uppercase text-align-left">
              LAUNCHING ON 6 STELLAR CHAINS
              </h2>
              <p className="text-white font-18 pt-2 fw-medium text-align-left">
              Cosmo endeavours to unite the crypto meme community and stand as the vanguard of multichain utility. As the first Doge Meme to be launched on six different blockchains, Cosmo’s only wish is to see his name up in lights on every DEX, every CEX, and every place where meme degens congregate. With a nose for interstellar convergence, Cosmo brings a multichain dimension to the meaning of trending on DEXTools.
              </p>
              <img src="img/palm.svg" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};